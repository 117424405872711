import React, { useState, useCallback, useContext } from "react";
import { shipmentApiNm, shipmentColNm } from "constants/shipmentConstant";
import ModAccName from "./ModAccName";
import { Box, MenuItem, FormControlLabel, Checkbox, Button, CircularProgress, TextField, Autocomplete, Typography, IconButton } from "@mui/material";
import ComboBox from "./ComboBox";
import { BoxFC, BoxFR } from "./BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { useMemo } from "react";
import { initRefColumnName } from "pages/Menu6/ServerDataManagement/constant";
import { alertWarning } from "./Alert";
// import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { isASCII, loadMSData } from "utils";
import DateTimePickerCustomTime from "./DateTimePickerCustomTime";
import JobDialog from "pages/Menu2/JobManagement/JobDialog";
import CustomerDialog from "pages/Menu2/CustomerManagement/CustomerDialog";
import Swal from "sweetalert2";
import TextFieldChangeLanguage from "./TextFieldChangeLanguage";
import { modifyPOICombo } from "branch/functions/modifyPOICombo";
import POIDialog from "pages/Menu2/POIManagement/POIDialog";
import { jobApiNm } from "constants/jobConstant";
import { branchApiNm } from "branch/constant/branchApiNm";
import { AddCircleRounded, RemoveCircleRounded } from "@mui/icons-material";
import { blueGrey } from "@mui/material/colors";
import { jobOrderColNm } from "constants/jobOrderConstant";

let currentPOICombo = "TakePOIId"
const initJobOrder = {
  AptTm: null,
  ContSize: "20",
  ContTyp: "GP",
  ContHq: 0,
  NumJobOrd: 1,
}

const generateMenuItem = (list) => (
  list.map(item => (
    <MenuItem key={item.id} value={item.id}>
      {item.label}
    </MenuItem>
  ))
)
const ShipmentData = ({ shipmentData, setShipmentData, disabled, hideModAcc, validation
  , oneYearVsslData, oneYearPortData, width = 600, dense = false }) => {

  const { ax, msData, setMSData } = useContext(GlobalStateContext)

  // const [jobCombo, setJobCombo] = useState(msData.jobCombo);
  const [isToUpper, setIsToUpper] = useState(true);
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [dialogJobOpen, setDialogJobOpen] = useState(false);
  const [dialogCusOpen, setDilaogCusOpen] = useState(false)
  const [dialogPOIOpen, setDialogPOIOpen] = useState(false)

  // const msCusCombo =  useMemo(()=>{
  //   const result = msData.cusCombo.map(item=>item)
  //   result.push({id: -1, label: "เพิ่มลูกค้าใหม่", group: "เพิ่มข้อมูล"})
  //   return result
  // }, [msData.cusCombo])

  const jobOrders = useMemo(() => {
    if (!shipmentData.JobOrders) {
      return []
    } else {
      return shipmentData.JobOrders
    }
  }, [shipmentData.JobOrders])

  const numJobOrd = useMemo(() => jobOrders.reduce((prev, cur) => prev + +cur.NumJobOrd, 0), [jobOrders])
  const jobCombo = useMemo(() => {
    let result = null
    if (shipmentData.CusId) {
      result = msData.jobCombo.filter(item => item.refId === shipmentData.CusId).map(item => item)
      result.push({ id: -1, label: "เพิ่มงานใหม่", group: "เพิ่มข้อมูล" })
    } else {
      result = []
    }
    return result
  }, [shipmentData.CusId, msData.jobCombo])

  const colWithRefName = useMemo(() => {

    const refColumnName = msData.serverData.RefColumnName || initRefColumnName
    const result = { ...shipmentColNm }
    result.Ref1 = refColumnName.shipment.Ref1
    result.Ref2 = refColumnName.shipment.Ref2
    return result
  }, [msData.serverData.RefColumnName])

  const dataProps = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    disabled: disabled,
    label: colWithRefName[name],
    error: validation ? validation[name] : false,
    fullWidth: true,
  }), [validation, disabled, colWithRefName])

  const jobOrderProps = useCallback((width, name, item, index) => ({
    size: "small",
    label: jobOrderColNm[name],
    value: item[name],
    sx: { width: width },
    disabled: shipmentData.ShpmId ? true : false,
    required: true,
    onChange: (e) => {
      setShipmentData(o => {
        const newJobOrders = [...o.JobOrders]
        newJobOrders[index][name] = e.target.value
        return { ...o, JobOrders: newJobOrders }
      })
    }
  }), [shipmentData.ShpmId, setShipmentData])

  const shipmentDataProp = useCallback((name) => ({
    ...dataProps(name),
    defaultValue: shipmentData[name],
    onBlur: async (e) => {
      if (isToUpper === true) e.target.value = e.target.value.toUpperCase();

      let value = e.target.value
      setShipmentData((oldState) => ({ ...oldState, [name]: value }))

      if (name === "JobNo" && value.length > 0) {
        if (!isASCII(e.target.value)) {
          const result = await Swal.fire({
            text: "มีอักขระอื่นนอกจากภาษาอังกฤษในช่อง JobNo ต้องการลบอักขระนั้นออกเลยหรือไม่?",
            icon: 'warning',
            confirmButtonText: 'OK',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
          })

          if (result.isConfirmed) {
            e.target.value = e.target.value.replace(/[^\x32-\x7F]/g, "")
          }
        }

        setIsShowLoading(true)

        ax.post(shipmentApiNm.getShipment, { ExactJobNo: value }, false).then(value => {
          setIsShowLoading(false)
          if (value.data) {
            if (value.data.length > 0) {
              alertWarning(`JobNo ${value} ซ้ำ`)
            }
          }
        })
      }
    }
  }), [shipmentData, dataProps, setShipmentData, isToUpper, ax])


  const shipmentDataPropControl = useCallback((name) => ({
    ...dataProps(name),
    value: shipmentData[name] || "",
    onChange: (e) => { setShipmentData((oldState) => ({ ...oldState, [name]: e.target.value })) }
  }), [shipmentData, dataProps, setShipmentData])


  const comboProp = useCallback((name, label, options) => ({
    options: options,
    label: label,
    disabled: disabled,
    required: true,
    sx: { width: "100%" },
    selectedId: shipmentData[name],
    setSelectedId: (id) => setShipmentData(oldState => {
      if (id === -1) {
        if (name === "CusId") {
          setDilaogCusOpen(true)
        }
        return { ...oldState, [name]: null }
      }
      return { ...oldState, [name]: id }
    })
  }), [shipmentData, setShipmentData, disabled])

  const datePickerProp = useCallback((name, isRequire) => ({
    label: shipmentColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: shipmentData[name],
    disableOpenPicker: disabled,
    disabled: disabled,
    onChange: (newValue) => { setShipmentData(oldState => ({ ...oldState, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" onChange={null} {...params} required={isRequire} fullWidth />,
  }), [shipmentData, disabled, setShipmentData])

  const datePickerPropAptTm = useCallback((item, index) => ({
    label: jobOrderColNm.AptTm,
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: item.AptTm,
    disabled: shipmentData.ShpmId ? true : false,
    onChange: (newValue) => {
      setShipmentData(o => {
        const newJobOrders = [...o.JobOrders]
        newJobOrders[index].AptTm = newValue
        return { ...o, JobOrders: newJobOrders }
      })
    },
    renderInput: (params) =>
      <TextField size="small" onChange={null} {...params} required={true} sx={{ flex: 1 }} error={!item.AptTm} />,
  }), [setShipmentData, shipmentData.ShpmId])

  const generateComboItem = useCallback((comboItems) => (
    comboItems.map((item) => (
      <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
    ))
  ), [])

  const generateJobNo = useCallback(() => {
    // ax.post(shipmentApiNm.generateJobNo, {}).then(value => {
    //   setShipmentData(o => ({ ...o, JobNo: value.data }))
    // })
    ax.post(branchApiNm.generateJobNo, {}).then(value => {
      if (value.data) {
        setShipmentData(o => ({ ...o, JobNo: value.data }))
      }
    })
  }, [ax, setShipmentData])

  const copyJobNo = useCallback(() => {
    console.log("copy clicked::")
    setShipmentData(o => ({ ...o, Bkg: o.JobNo }))
  }, [setShipmentData])

  const onCusFinish = useCallback(async (id) => {
    await loadMSData(ax, setMSData, null)
    setShipmentData(o => ({ ...o, CusId: id }))
  }, [ax, setMSData, setShipmentData])
  const onJobFinish = useCallback((id) => {
    loadMSData(ax, setMSData, null)
    setShipmentData(o => ({ ...o, JobId: id }))
  }, [ax, setMSData, setShipmentData])

  const onPOIFinish = useCallback(async (id) => {
    await loadMSData(ax, setMSData, null)
    setShipmentData(o => ({ ...o, [currentPOICombo]: id }))
  }, [ax, setMSData, setShipmentData])

  const handleSelecPOIId = useCallback((name) => (id) => {
    if (id === -1) {
      currentPOICombo = name
      setDialogPOIOpen(true)
      setShipmentData(oldState => ({ ...oldState, [name]: null }))
    } else {
      setShipmentData(oldState => ({ ...oldState, [name]: id }))
    }
  }, [setShipmentData])

  const handleAutoCompleteSelect = useCallback((name) => (e, newValue) => {
    setShipmentData(o => ({ ...o, [name]: newValue }))
  }, [setShipmentData])

  const handleAutoCompleteTextChange = useCallback((name) => (e) => {
    setShipmentData(o => ({ ...o, [name]: e.target.value }))
  }, [setShipmentData])

  const handleAutoCompleteTextBlur = useCallback((name) => e => {
    if (isToUpper) {
      setShipmentData(o => ({ ...o, [name]: o[name]?.toUpperCase() || "" }))
    }
  }, [isToUpper, setShipmentData])

  const handleJobComboChange = useCallback((id) => {
    if (id === -1) {
      setDialogJobOpen(true)
      setShipmentData(o => ({ ...o, JobId: null }))
      return
    }
    setShipmentData(o => ({ ...o, JobId: id }))
    ax.post(jobApiNm.getJob, { JobId: id }, false).then(value => {
      if (value.data) {
        if (value.data.length > 0 && value.data[0].POIId) {
          setShipmentData(o => ({ ...o, LocPOIId: value.data[0].POIId }))
        }
      }
    })
  }, [setShipmentData, ax])

  const handleRemoveJobOrderRow = useCallback((index) => {
    setShipmentData(o => {
      const newJobOrders = [...o.JobOrders]
      newJobOrders.splice(index, 1)
      return { ...o, JobOrders: newJobOrders }
    })
  }, [setShipmentData])
  console.log("render shipmentdata shipmentData::", shipmentData)
  console.log("render shipmentdata msData::", msData)
  return (
    <>
      <Box display="flex" flexGrow={1} flexDirection="column" gap={1} key={[shipmentData.ShpmId]} width={width}>
        {!disabled &&
          <FormControlLabel sx={{ m: 0 }}
            label="ใช้อักษรพิมพ์ใหญ่(ภาษาอังกฤษ)"
            control={<Checkbox sx={{ p: 0 }} checked={isToUpper} onChange={(e) => setIsToUpper(e.target.checked)} />}
          />}
        <Box display="flex" flexDirection="row" gap={1}>
          <DateTimePickerCustomTime {...datePickerProp("RecvDocDte", true)} />
          <TextField {...shipmentDataPropControl("ShpmTypId")} required select>
            {generateComboItem(msData.ShpmTypCombo)}
          </TextField>
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <ComboBox {...comboProp("CusId", shipmentColNm.CusSNm, msData.cusComboWithAdd)} />
          <BoxFR width="100%">
            <ComboBox {...comboProp("JobId", shipmentColNm.JobSNm, jobCombo)} setSelectedId={handleJobComboChange} />
            {
              // !shipmentData.ShpmId &&
              // <Button color="primary" variant="contained" onClick={() => loadMSData(ax, setMSData, null)}><RefreshRoundedIcon /></Button>
            }

          </BoxFR>
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <BoxFR width="100%">
            <TextFieldChangeLanguage {...shipmentDataProp("JobNo")} key={shipmentData.JobNo} required />
            {
              isShowLoading ?
                <BoxFR width={60} justifyContent="center">
                  <CircularProgress color="secondary" />
                </BoxFR>
                : !disabled &&
                <Button variant="contained" tabIndex={-1} onClick={generateJobNo} sx={{ width: 60 }}>Gen</Button>
            }
          </BoxFR>
          <BoxFR width="100%"   >
            <TextFieldChangeLanguage {...shipmentDataProp("Bkg")} key={shipmentData.Bkg} />
            {!disabled &&
              <Button variant="contained" sx={{ width: 60 }} onClick={copyJobNo}><ContentCopyIcon /></Button>}
          </BoxFR>
        </Box>

        <Box display="flex" flexDirection="row" gap={1}>
          <ComboBox sx={{ width: "100%" }} disabled={disabled}
            options={modifyPOICombo(msData.poiComboWithAdd, "TAKE", shipmentData.ShpmTypId)}
            label={shipmentColNm.TakePlc}
            selectedId={shipmentData.TakePOIId}
            setSelectedId={handleSelecPOIId("TakePOIId")} />
          <ComboBox sx={{ width: "100%" }} disabled={disabled}
            options={modifyPOICombo(msData.poiComboWithAdd, "LOC", shipmentData.ShpmTypId)}
            label={shipmentColNm.Loc}
            selectedId={shipmentData.LocPOIId}
            setSelectedId={handleSelecPOIId("LocPOIId")} />
          <ComboBox sx={{ width: "100%" }} disabled={disabled}
            options={modifyPOICombo(msData.poiComboWithAdd, "RTN", shipmentData.ShpmTypId)}
            label={shipmentColNm.RtnPlc}
            selectedId={shipmentData.RtnPOIId}
            setSelectedId={handleSelecPOIId("RtnPOIId")} />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <TextFieldChangeLanguage tabIndex={10} {...shipmentDataProp("Agnt")} key={shipmentData.Agnt} />
          <Autocomplete freeSolo options={oneYearPortData || []} value={shipmentData.Port} sx={{ width: "100%" }}
            onChange={handleAutoCompleteSelect("Port")}
            renderInput={(params) => (<TextField {...params}
              onChange={handleAutoCompleteTextChange("Port")} onBlur={handleAutoCompleteTextBlur("Port")} size="small" label="Port" />)} />
          {/* <TextFieldChangeLanguage tabIndex={11}{...shipmentDataProp("Port")} /> */}
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <Autocomplete freeSolo options={oneYearVsslData || []} value={shipmentData.Vssl} sx={{ width: "100%" }}
            onChange={handleAutoCompleteSelect("Vssl")}
            renderInput={(params) => (<TextField {...params}
              onChange={handleAutoCompleteTextChange("Vssl")} onBlur={handleAutoCompleteTextBlur("Vssl")} size="small" label="Vessel" />)} />
          {/* <TextFieldChangeLanguage tabIndex={9}{...shipmentDataProp("Vssl")} /> */}
          <TextFieldChangeLanguage {...shipmentDataProp("Voy")} key={shipmentData.Voy} />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <DateTimePickerCustomTime {...datePickerProp("ETA", false)} />
          <DateTimePickerCustomTime {...datePickerProp("ETD", false)} />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <DateTimePickerCustomTime {...datePickerProp("TakeDteSt", false)} />
          <DateTimePickerCustomTime {...datePickerProp("TakeDteEn", false)} />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <DateTimePickerCustomTime {...datePickerProp("RtnDteSt", false)} />
          <DateTimePickerCustomTime {...datePickerProp("RtnDteEn", false)} />
        </Box>
        <Box display="flex" flexDirection="row" gap={1}>
          <TextFieldChangeLanguage {...shipmentDataProp("Ref1")} />
          <TextFieldChangeLanguage {...shipmentDataProp("Ref2")} />
        </Box>
        <TextFieldChangeLanguage {...shipmentDataProp("Rmk")} multiline rows={1} />
        <BoxFC p={1} bgcolor={blueGrey[50]} sx={{ gap: 1 }}>
          <BoxFR sx={{ zIndex: 1000, height: 40 }}>
            <Typography fontWeight="bold" >ใบงาน ({numJobOrd})</Typography>
            {shipmentData.ShpmId ? null :
              <IconButton sx={{ p: 0 }} onClick={() => { setShipmentData(o => ({ ...o, JobOrders: [...(o.JobOrders || []), { ...initJobOrder }] })) }}>
                <AddCircleRounded color="primary" />
              </IconButton>
            }
            {shipmentData.ShpmId || jobOrders.length === 0 ? null :
              <TextField size="small" {...shipmentDataProp("JORmk")} key={shipmentData.JORmk}  
              label="เพิ่มหมายเหตุใบงาน" sx={{ ml: 3, flex: 1 }} />
            }
          </BoxFR>
          <Box height={95} sx={{ overflow: "hidden", overflowY: "auto", pt: 1, mt: -1 }}>
            <BoxFC sx={{ gap: 1 }}>
              {
                jobOrders.map((item, index) => (
                  <BoxFR key={index}>
                    {/* <Typography width={20}>{index + 1}.</Typography> */}
                    <TextField {...jobOrderProps(70, "NumJobOrd", item, index)} label="จำนวน" type="number"
                      inputProps={{ style: { textAlign: "center" } }} />
                    <TextField select {...jobOrderProps(80, "ContSize", item, index)} >{generateMenuItem(msData.contSizeCombo)}</TextField>
                    <TextField select {...jobOrderProps(80, "ContTyp", item, index)}  >{generateMenuItem(msData.contTypCombo)}</TextField>
                    <TextField select {...jobOrderProps(80, "ContHq", item, index)} >
                      <MenuItem value={0}>Std</MenuItem>
                      <MenuItem value={1}>HQ</MenuItem>
                    </TextField>
                    <DateTimePickerCustomTime {...datePickerPropAptTm(item, index)} />
                    {shipmentData.ShpmId ? null :
                      <IconButton p={0} onClick={()=>handleRemoveJobOrderRow(index)}>
                        <RemoveCircleRounded />
                      </IconButton>
                    }
                  </BoxFR>
                ))
              }
            </BoxFC>
          </Box>
        </BoxFC>
      </Box>
      {
        !hideModAcc && shipmentData.ShpmId > 0 && <ModAccName modDte={shipmentData.ModDte} modAcc={shipmentData.ModAcc} />
      }
      <CustomerDialog state={{ dialogOpen: dialogCusOpen, selectedCusId: 0 }}
        fn={{
          setDialogOpen: setDilaogCusOpen,
          onFinish: onCusFinish
        }} />
      <JobDialog state={{ dialogOpen: dialogJobOpen, selectedId: 0, selectedCusId: shipmentData.CusId }}
        fn={{
          setDialogOpen: setDialogJobOpen,
          onFinish: onJobFinish
        }} />

      <POIDialog selectedId={0} dialogOpen={dialogPOIOpen} setDialogOpen={setDialogPOIOpen}
        onFinish={onPOIFinish} lastFilter={null} />
    </>
  )
}

export default React.memo(ShipmentData);